import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateSelector } from '../../../../inputs/DateSelector';
import { Select } from '../../../../inputs/Select';
import { TextField } from '../../../../inputs/TextField';
import Checkbox from '../../../../inputs/Checkbox';
import { COUNTRIES_FOR_SELECT } from '../../../../../constants';
import { subscribeTo } from '../../../../../sharedStore';
import MultipleChoiceSelect from '../MultipleChoiceSelect';
import MoneyTextField from '../../../../inputs/MoneyTextField';
import useReferenceName from '../../../../hooks/useReferenceName';
import useUpdateReferencesTags from '../../../../hooks/useUpdateReferencesTags';
import { toHtmlWithStyledReferences } from '../../../../../utils';
import Box from '@mui/material/Box';
import RichTextField from '../../../../inputs/RichTextField';
import UploadField from '../../../../inputs/UploadField';

export const currentlyEmployedOptions = [
  { value: '', name: 'Are you currently employed?' },
  { value: 'true', name: 'Yes, I am currently employed.' },
  { value: 'false', name: 'No, I am not currently employed.' },
];

export const DataFieldWrapper = ({ type, ...props }) => {
  const [errorMessage, setErrorMessage] = React.useState(props.error || null);
  const parsedHtml =
    props.isPreview && useUpdateReferencesTags(props.richText.titleHtml);
  const previewHtmlLabel =
    props.isPreview && toHtmlWithStyledReferences(parsedHtml);

  useEffect(() => {
    subscribeTo(`dataFieldError_${props.errorKey}`, handleStimulusError);
  }, []);

  const handleStimulusError = (value) => {
    setErrorMessage(value);
  };

  const removeError = () => {
    setErrorMessage(null);
  };

  const baseProps = {
    onFocus: removeError,
    helperText: errorMessage,
    error: !!errorMessage,
    label: (
      <Box
        sx={{
          'span:last-child:after': {
            content: props.required ? "'*'" : "''",
          },
          'a span:last-child:after': {
            content: "''",
          },
        }}
        dangerouslySetInnerHTML={{
          __html: previewHtmlLabel || props.richText.titleHtml,
        }}
      />
    ),
  };

  const previewDefaultValue = {
    ...(props.isPreview && {
      defaultValue: useReferenceName(props.defaultValue),
    }),
  };

  const buildMultipleChoiceOptions = () => {
    const initialOption = [{ value: '', title: '' }];

    const preparedMultipleChoiceOptions = props.multipleChoiceOptions.map(
      (option) => ({
        value: option.richText.title,
        name: option.richText.title,
        id: option.id,
      }),
    );

    return [...initialOption, ...preparedMultipleChoiceOptions];
  };

  switch (type) {
    case 'date_of_birth':
      return <DateSelector {...props} {...baseProps} />;
    case 'country':
      return (
        <Select
          {...props}
          {...baseProps}
          cssStyle={props.cssStyle}
          options={[{ value: '', name: '' }, ...COUNTRIES_FOR_SELECT]}
          defaultValue={props.defaultValue || ''}
        />
      );
    case 'currently_employed':
      return (
        <Select
          {...props}
          {...baseProps}
          cssStyle={props.cssStyle}
          isCurrentlyEmployed
          options={currentlyEmployedOptions}
        />
      );
    case 'multiple_choice':
      return (
        <MultipleChoiceSelect
          {...props}
          {...baseProps}
          options={buildMultipleChoiceOptions()}
        />
      );
    case 'integer':
      return (
        <TextField
          {...props}
          {...baseProps}
          {...previewDefaultValue}
          type={props.isPreview ? 'text' : 'number'}
        />
      );
    case 'money':
      return (
        <MoneyTextField {...props} {...baseProps} {...previewDefaultValue} />
      );
    case 'checkbox':
      return (
        <Checkbox {...props} {...baseProps} />
      );
    case 'rich_text':
      return (
        <RichTextField {...props} {...baseProps} />
      );
    case 'upload':
      return (
        <UploadField {...props} {...baseProps} />
      );
    default:
      return (
        <TextField
          {...props}
          {...baseProps}
          {...previewDefaultValue}
          multiline={type === 'paragraph_text'}
        />
      );
  }
};

DataFieldWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cssStyle: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  onSelectedValue: PropTypes.func,
  currency: PropTypes.string.isRequired,
  errorKey: PropTypes.string.isRequired,
  error: PropTypes.string,
};
